// utils.js


// Create an advanced marker for a given venue or event
function createAdvancedMarker(markerData) {
    if (!map) {
        console.error("Map is not initialized.");
        return;
    }

    let content;

    if (markerData.type === 'event') {
        const iconUrl = 'img/event-marker.svg';
        const iconSize = { width: 30, height: 30 };
        content = document.createElement('div');
        content.innerHTML = `<img src="${iconUrl}" style="width:${iconSize.width}px; height:${iconSize.height}px;">`;

    } else if (markerData.type === 'vibe') {
        let iconUrl;
        let iconSize = { width: 60, height: 60 };

        if (markerData.liked === 1) {
            iconUrl = 'img/liked-marker.svg';
        } else if (markerData.liked === 0) {
            iconUrl = 'img/unliked-marker.svg';
        }


        content = document.createElement('div');
        content.innerHTML = `<img src="${iconUrl}" style="width:${iconSize.width}px; height:${iconSize.height}px;">`;

    } else {
        // Venue marker logic (default)
        let markerClass = 'venue-marker'; // Default to no additional class
        const vibeScore = markerData.vibeScore || 0;


        // Add vibe-related classes without replacing existing ones
        if (markerData.likes > 0 || markerData.dislikes > 0) {
            if (vibeScore > 0) {
                markerClass += ' positive'; // Add 'positive' class
            } else if (vibeScore < 0) {
                markerClass += ' negative'; // Add 'negative' class
            } else {
                markerClass += ' neutral'; // Add 'neutral' class
            }
        }


        content = document.createElement('div');
        content.className = markerClass;
        content.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 39">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.646 6.946c-1.48-2.305-3.585-4.22-6.053-5.413C19.02.285 16.107-.245 13.267.104A15.203 15.203 0 0 0 5.509 3.39C3.365 5.123 1.75 7.467.835 10.059a14.987 14.987 0 0 0-.086 9.61c.606 1.83 1.666 3.5 2.736 5.085 1.341 1.991 2.802 3.891 4.301 5.763a160.787 160.787 0 0 0 4.106 4.912 133.956 133.956 0 0 0 2.38 2.68c.018.021.035.041.055.06.338.368 1.012.368 1.35 0 .466-.508.922-1.021 1.378-1.534l.007-.008a180.275 180.275 0 0 0 3.309-3.852 130.871 130.871 0 0 0 4.067-5.118c1.203-1.6 2.373-3.237 3.385-4.965.434-.745.826-1.504 1.16-2.301.55-1.313.836-2.731.96-4.144.038-.416.057-.836.057-1.256v-.01c-.014-2.826-.826-5.647-2.354-8.035ZM15.059 19.863a4.806 4.806 0 1 0 0-9.611 4.806 4.806 0 0 0 0 9.611Z"
                    fill="url(#default)" />
                <defs>
                <linearGradient id="default" x1="15" y1="9.131" x2="15" y2="58.949" gradientUnits="userSpaceOnUse"><stop stop-color="#01BBFF"/><stop offset="1" stop-color="#E503A3"/></linearGradient>
                    <linearGradient id="neutral" x1="15" y1="9.13073" x2="15" y2="38" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFD600" />
                        <stop offset="1" stop-color="#FFA800" />
                    </linearGradient>
                    <linearGradient id="negative" x1="15" y1="9.13073" x2="15" y2="58.9493"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EA3D56" />
                        <stop offset="1" stop-color="#8B091C" />
                    </linearGradient>
                    <linearGradient id="positive" x1="15" y1="9.13073" x2="15" y2="58.9493"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#71E409" />
                        <stop offset="1" stop-color="#254E00" />
                    </linearGradient>
                </defs>
            </svg><div class="pulse"></div>`;
    }

    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        map: map,
        content: content,
        title: `Marker ID: ${markerData.place_id || markerData.id}`,
    });

    marker.metadata = {
        type: markerData.type,
        id: markerData.id,
        place_id: markerData.place_id || null,
        liked: markerData.liked || null,
        vibeScore: markerData.vibeScore || 0,
        likes: markerData.likes || 0,        // Added likes count
        dislikes: markerData.dislikes || 0   // Added dislikes count
    };

    marker.addListener('gmp-click', function () {
        //console.log('Marker clicked:', marker.metadata);

        // if (!marker.metadata.place_id) {
        //     console.error('Error: place_id is undefined in marker metadata');
        //     return;
        // }

        if (marker.metadata.type === 'event') {
            const event = events.find(e => e.id === marker.metadata.id);
            if (event) {
                //showEventDetails(event);
            } else {
                console.error('Event not found for marker:', marker.metadata.id);
            }
        } else if (marker.metadata.type === 'vibe') {
            showVibeDetails(marker.metadata.id);
        } else if (marker.metadata.type === 'venue') {
            $.ajax({
                type: 'GET',
                url: 'get_venue_details.php',
                data: { place_id: marker.metadata.place_id },
                dataType: 'json',
                success: function (response) {
                    if (response.success && response.data) {
                        console.log('Fetched venue details from DB:', response.data);
                        displayVenueDetails(response.data);
                        fetchVibes(marker.metadata.place_id);
                    } else {
                        console.warn('Venue not found in DB, fetching from Google API');
                        fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
                    }
                },
                error: function (xhr, status, error) {
                    console.error('Error fetching venue details from DB:', error);
                    fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
                }
            });
        } else {
            console.warn('Unknown marker type:', marker.metadata.type);
        }
    });

    markers.push(marker);
}




// Function to add a generic marker, used for specific vibe markers
function addMarker(markerData) {
    let iconUrl = 'img/venue-marker.svg'; // Default to venue marker

    if (markerData.liked === 1) {
        iconUrl = 'img/liked-marker.svg';
    } else if (markerData.liked === 0) {
        iconUrl = 'img/unliked-marker.svg';
    }

    // console.log(`Adding marker for ${markerData.name || 'Unknown'} with icon: ${iconUrl}`);

    // Create a content div for the advanced marker
    const content = document.createElement('div');
    content.innerHTML = `<img src="${iconUrl}" style="width:30px; height:30px;">`;

    // Create an AdvancedMarkerElement
    const marker = new google.maps.marker.AdvancedMarkerElement({
        map: map,
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        content: content,
        title: `Marker ID: ${markerData.id}`,
    });

    // Store metadata in marker for reference
    marker.metadata = {
        id: markerData.id,
        name: markerData.name,
        liked: markerData.liked,
    };

    // Add a click listener to show venue details
    marker.addListener('gmp-click', function () {
        map.setCenter(marker.position);
        fetchMarkerDetails(marker.metadata.id, marker);
    });

    markers.push(marker);
}


// Debounce function for the slider
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

// Function to fetch detailed information about a venue using Google Places API
function fetchGoogleVenueDetails(placeId, marker, shouldSave = false) {
    const service = new google.maps.places.PlacesService(map);

    service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            const { place_id, name, formatted_address, geometry, photos, opening_hours, rating } = place;
            const photoUrls = photos ? photos.map(photo => photo.getUrl({ maxWidth: 400 })) : [];

            const venueDetails = {
                place_id: place_id,
                name: name,
                address: formatted_address || 'Address not available',
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
                photo_urls: photoUrls,
                opening_hours: opening_hours ? opening_hours.weekday_text : null,
                google_rating: rating || null
            };

            console.log('Photos from Google Places:', photoUrls);

            // Define photosHtml for use in the detailsContent
            let photosHtml = '';
            if (photoUrls.length > 0) {
                photosHtml = photoUrls.map(photoUrl => `<div class='swiper-slide'><img src="${photoUrl}" alt="Venue Image"></div>`).join('');
            } else {
                photosHtml = `<div class='swiper-slide'><img src="img/placeholder.png" alt="Default Image"></div>`;
            }

            let openingHoursHtml = 'Opening hours not available';

            if (opening_hours && Array.isArray(opening_hours.weekday_text)) {
                openingHoursHtml = opening_hours.weekday_text.join('<br/>');
            }

            const detailsContent = `
                <div class="swiper mySwiper">
                    <div class="swiper-wrapper">
                        ${photosHtml}
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
                <div class="pad">
                    <div class="venue-name">${name || 'Not available'}</div>
                    <div class="venue-rating">Goolge rating: ${rating || 'Not available'}</div>
                    <div class="opening-hours"><strong>Opening hours:</strong><br/>${openingHoursHtml}</div>
                </div>
            `;

            // Immediately display the details
            const venueDetailsElement = document.getElementById('venue-details');
            venueDetailsElement.innerHTML = detailsContent;

            if (photoUrls.length > 0) {
                // Only initialize Swiper if there are photos
                window.mySwiper = new Swiper('.mySwiper', {
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    slidesPerView: 3,
                    spaceBetween: 5,
                });
            }

            setIsSheetShown($('#venueSheet'), true);

            if (shouldSave) {
                venueDetails.photo_urls = JSON.stringify(photoUrls);
                venueDetails.opening_hours = JSON.stringify(opening_hours ? opening_hours.weekday_text : null);

                $.ajax({
                    type: 'POST',
                    url: 'save_venue_details.php',
                    data: venueDetails,
                    success: function (response) {
                        //console.log('Venue details saved successfully:', response);
                    },
                    error: function (xhr, status, error) {
                        console.error('Error saving venue details:', error);
                    }
                });
            }
        } else {
            console.error('Failed to fetch venue details:', status);
        }
    });
}


function displayVenueDetails(data) {
    const venueDetailsElement = document.getElementById('venue-details');
    const eventInfoElement = document.getElementById('event-info');

    if (!data) {
        console.error('Error: No data provided to displayVenueDetails');
        return;
    }

    // Handle opening hours, ensuring it's parsed correctly
    let openingHours = 'Opening hours not available';
    if (data.opening_hours) {
        try {
            if (Array.isArray(data.opening_hours)) {
                openingHours = data.opening_hours.join('<br>');
            } else {
                const parsedOpeningHours = JSON.parse(data.opening_hours);
                if (Array.isArray(parsedOpeningHours)) {
                    openingHours = parsedOpeningHours.join('<br>');
                }
            }
        } catch (error) {
            console.error('Error parsing opening_hours:', error);
        }
    }

    // Handle venue photos, fallback to default if none exist
    let photosHtml = '';
    if (Array.isArray(data.photo_urls) && data.photo_urls.length > 0) {
        photosHtml = data.photo_urls.map(photoUrl => 
            `<div class='swiper-slide'><img src="${photoUrl}" alt="Venue Image"></div>`
        ).join('');
    } else {
        photosHtml = `<div class='swiper-slide'><img src="default-image.jpg" alt="Default Image"></div>`;
    }

    // Venue details content
    const detailsContent = `
        <div class="swiper mySwiper">
            <div class="swiper-wrapper">
                ${photosHtml}
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div class="pad">
            <div class="venue-name">${data.name || 'Not available'}</div>
            <div class="venue-rating">Google rating: ${data.google_rating || 'Not available'}</div>
            <div class="opening-hours"><strong>Opening hours:</strong><br/>${openingHours}</div>
        </div>
    `;

    // Inject venue details into the appropriate element
    venueDetailsElement.innerHTML = detailsContent;

    // Fetch events from the AJAX response (replace 'events' with your actual fetched events)
    fetchEventsForVenue(data.place_id, eventInfoElement);

    // Initialize Swiper (for the venue photos)
    window.mySwiper = new Swiper('.mySwiper', {
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        slidesPerView: 2,
        spaceBetween: 5,
    });

    // Show the venue details sheet
    setIsSheetShown($('#venueSheet'), true);
}






// Function to show or hide the venue sheet
function showVenueSheet(isShown) {
    const sheet = $('#venueSheet');
    sheet.attr("aria-hidden", String(!isShown));

    if (isShown) {
        // Optionally add animations or transitions
        sheet.addClass('visible'); // You can define .visible in your CSS for visibility animations
    } else {
        sheet.removeClass('visible');
    }
}
