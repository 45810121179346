// Global variable to track the interval ID
let positionIntervalId = null;

// Track if the user is interacting with the dropdown
let userInteractingWithDropdown = false;


// Function to calculate distance using Turf.js
function calculateDistance(userLocation, venueLocation) {
    if (!userLocation || !venueLocation) return null;

    // Create Turf.js points
    const from = turf.point([userLocation.lng, userLocation.lat]);
    const to = turf.point([venueLocation.lng, venueLocation.lat]);

    // Calculate distance in kilometers
    const options = { units: 'kilometers' };
    const distance = turf.distance(from, to, options);

    return distance;
}

// Function to update distance display for a venue
function updateDistanceDisplay(venueElement, distance) {
    const distanceDisplay = venueElement.querySelector('.distance-display');
    if (distanceDisplay && distance !== null) {
        distanceDisplay.innerText = `${distance.toFixed(2)} km`;
    }
}
// Function to fetch and update the user's position
// venues.js

// Fetch and update the user's position
function fetchAndUpdatePosition() {
    if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Update userLocation globally declared in map.js
                userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                // Update distances only if the explore view is open
                const exploreSheet = document.getElementById('sheet-explore');
                if (exploreSheet && exploreSheet.getAttribute('aria-hidden') === 'false') {
                    document.querySelectorAll('.venue').forEach((venueElement) => {
                        const venueLocation = {
                            lat: parseFloat(venueElement.dataset.lat),
                            lng: parseFloat(venueElement.dataset.lng),
                        };
                        const distance = calculateDistance(userLocation, venueLocation);
                        updateDistanceDisplay(venueElement, distance);
                    });
                }

                // Update user's marker on the map
                if (typeof updateUserMarker === 'function') {
                    updateUserMarker(userLocation);
                }

                // Update the nearby bars dropdown only if not interacting
                if (!userInteractingWithDropdown) {
                    updateNearbyBarsDropdown();
                }
            },
            (error) => {
                console.error('Error fetching position:', error);
            },
            {
                enableHighAccuracy: true,
                maximumAge: 5000, // Reduce maximum cached position age
                timeout: 10000, // Increase timeout for getting position
            }
        );
    } else {
        console.error('Geolocation is not supported by this browser.');
    }
}


// Setup event listener to start fetching position every 5 seconds
document.addEventListener('DOMContentLoaded', () => {
    // Start fetching the user's position every 5 seconds
    positionIntervalId = setInterval(fetchAndUpdatePosition, 5000);

    // Optionally, fetch the position immediately on load
    fetchAndUpdatePosition();
});


// venues.js
function updateNearbyBarsDropdown() {
    const dropdown = document.getElementById('nearby-bars-dropdown');
    if (!dropdown) return;

    // Clear existing options
    dropdown.innerHTML = '';

    // Filter bars within 100 meters (0.1 km)
    const nearbyBarsWithinRange = nearbyBars.filter(bar => bar.distance <= 0.1);

    // Add nearby bars to dropdown
    nearbyBarsWithinRange.forEach(bar => {
        const option = document.createElement('option');
        option.value = bar.place_id;
        //option.textContent = `${bar.name} (${(bar.distance * 1000).toFixed(0)} meters)`;
        option.textContent = `${bar.name}`;
        dropdown.appendChild(option);
    });

    // Add 'Other' option
    const otherOption = document.createElement('option');
    otherOption.value = 'other';
    otherOption.textContent = 'Other';
    dropdown.appendChild(otherOption);

    // Add or remove class based on proximity to any bar
    if (nearbyBarsWithinRange.length > 0) {
        document.body.classList.add('user-in-bar');
    } else {
        document.body.classList.remove('user-in-bar');
    }
}

// Check if the user has selected a venue
document.getElementById('nearby-bars-dropdown').addEventListener('focus', () => {
    userInteractingWithDropdown = true;
});

document.getElementById('nearby-bars-dropdown').addEventListener('blur', () => {
    // Delay the reset to allow smooth user interaction
    setTimeout(() => {
        userInteractingWithDropdown = false;
    }, 2000); // Adjust the delay time as needed
});
