// map.js
// Global variables
var map;
var markers = []; // Array to hold all markers
let userLocation = null; // User's location
// let userLocation = { lat: 50.9123814, lng: -1.4049011 }; //orange rooms
//let userLocation = { lat: 51.0631103, lng: -1.3169268 };
//let userLocation = { lat: 51.5011343, lng: -0.1118477 }; //waterloo

// uncomment line 36ish when done debugging
let userMarker = null; // Marker for user's location
let nearbyBars = [];

// Default location (used if user denies location access)
//const defaultLocation = { lat: 51.0606892, lng: -1.3131538 }; // winch
//const defaultLocation = { lat: 51.5191715, lng: -0.0963904 }; // london
const defaultLocation = { lat: 51.5011343, lng: -0.1118477 }; // london waterloo


// Function to update the user's location marker on the map
function updateUserMarker(location) {
    if (userMarker) {
        // Update the existing marker's position
        userMarker.position = new google.maps.LatLng(location.lat, location.lng);
        userMarker.map = map; // Ensure marker is on the map
        //console.log('Marker updated to new position:', location);
    } else {
        // Create a new marker if none exists
        addUserLocationMarker();
    }
}


// Initialize the Google Map
function initMap() {
    map = new google.maps.Map(document.getElementById('map'), {
        center: defaultLocation,
        zoom: 14,
        fullscreenControl: false,
        mapId: 'f6b957fb3fe1b7bb',
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: true,
        scaleControl: false,
    });

    initCenterControl();

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    // lat: 51.5011343, 
                    // lng: -0.1118477 
                };
                map.setCenter(userLocation);
                addUserLocationMarker();
                fetchBarsNearUser();

            },
            () => {
                handleLocationError(true, defaultLocation);
            }
        );
    } else {
        handleLocationError(false, defaultLocation);
    }

    google.maps.event.addListenerOnce(map, 'idle', () => {
        loadVibeMarkers();
        fetchVibesAndEventsForVisibleVenueMarkers();
        debug();
    });
    


    // Use the debounced version for fetching vibes when the user interacts with the map
    // steve comment
    map.addListener('idle', debouncedFetchVibes);
}



// Initialize the custom center control functionality
function initCenterControl() {
    const centerControlElement = document.getElementById('center-control');

    centerControlElement.addEventListener('click', () => {
        if (userLocation) {
            map.setCenter(userLocation);
            map.setZoom(14); // Optional: Set a default zoom level when centering
        } else {
            map.setCenter(defaultLocation);
            map.setZoom(14);
        }
    });
}

// Add a marker for the user's location using the AdvancedMarkerElement
function addUserLocationMarker() {
    if (userLocation) {
        if (userMarker) {
            userMarker.map = null; // Remove existing marker
        }

        // Create an advanced marker for the user's location
        const content = document.createElement('div');
        content.innerHTML = `<div class="users-location"></div>`;

        userMarker = new google.maps.marker.AdvancedMarkerElement({
            position: userLocation,
            map: map,
            content: content
        });

        markers.push(userMarker);
    }
}

// Error handling for geolocation
function handleLocationError(browserHasGeolocation, pos) {
    // Set userLocation to defaultLocation if geolocation fails
    userLocation = pos;
    map.setCenter(userLocation);

    // Load markers even if geolocation fails
    fetchBarsNearUser();
    loadAllEvents();
    loadVibeMarkers();

    const infoWindow = new google.maps.InfoWindow({
        position: pos,
        content: browserHasGeolocation
            ? "Error: The Geolocation service failed. Showing default location."
            : "Error: Your browser doesn't support geolocation. Showing default location.",
    });
    //infoWindow.open(map);
}

// Load vibe markers
function loadVibeMarkers() {
    if (typeof filterMarkersByDateRange === 'function') {
        // Filter for the last 2 hours or as required
        filterMarkersByDateRange(2);
    } else {
        console.error("Vibe loading function is not defined.");
    }
}

// Fetch nearby bars and nightclubs using Google Places API
function fetchBarsNearUser(lat = userLocation.lat, lng = userLocation.lng) {
    if (!lat || !lng) {
        console.error('Location not available.');
        return;
    }

    const service = new google.maps.places.PlacesService(map);
    const types = ['bar'];

    types.forEach(type => {
        const request = {
            location: new google.maps.LatLng(lat, lng),  // Use lat and lng passed in or default to userLocation
            radius: 1500,  // Search within 1500 meters
            type: type,
            keyword: 'pub',
        };

        service.nearbySearch(request, handleSearchResults);
    });
}



// Handle the results of the nearby search
function handleSearchResults(results, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
        nearbyBars = results; // Store results for later use
        const barsUl = document.getElementById('barsUl');
        barsUl.innerHTML = ''; // Clear any previous bars

        // Calculate distances for sorting
        results.forEach(feature => {
            const from = new google.maps.LatLng(userLocation.lat, userLocation.lng);
            const to = new google.maps.LatLng(feature.geometry.location.lat(), feature.geometry.location.lng());
            feature.distance = google.maps.geometry.spherical.computeDistanceBetween(from, to) / 1000; // Convert to kilometers
        });

        // Sort results by distance
        results.sort((a, b) => a.distance - b.distance);

        // Display the results
        results.slice(0, 20).forEach(feature => {
            const venueName = feature.name;
            const barLat = feature.geometry.location.lat();
            const barLng = feature.geometry.location.lng();

            // Create advanced marker for each venue using only place_id
            createAdvancedMarker({
                type: 'venue',
                latitude: barLat,
                longitude: barLng,
                liked: null, // No like status for new venues
                place_id: feature.place_id, // Use place_id
                name: venueName
            });


            // Append to list in the specified format
            const li = document.createElement('li');
            li.className = 'venue';
            li.dataset.lat = barLat;
            li.dataset.lng = barLng;

            li.innerHTML = `
                <span class="venue-name">${venueName}</span>
                <span class="distance-display badge">${feature.distance.toFixed(2)} km</span>
            `;
            barsUl.appendChild(li);
        });

        // Update nearby bars dropdown
        updateNearbyBarsDropdown();
    } else {
        console.error('Error fetching bars:', status);
    }
}

