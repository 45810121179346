$(document).ready(function () {
    const DEFAULT_SHEET_HEIGHT = 99; // Default sheet height in vh for draggable sheets
    const MIN_SHEET_HEIGHT = 20; // Minimum sheet height in vh for draggable sheets

    // Utility function to set up the sheet
    function setupSheet(sheet) {
        const contents = sheet.find('.sheet-contents');

        // Add controls header with a close button if it doesn't exist
        if (!contents.find('.controls').length) {
            const controls = $('<header>', { class: 'controls' });

            // Add draggable area first if the sheet is draggable
            if (sheet.hasClass('draggable-sheet') && !contents.find('.draggable-area').length) {
                const draggableArea = $('<div>', { class: 'draggable-area' });
                controls.append(draggableArea);
            }
            const closeButton = $('<span>', {
                class: 'close-sheet',
                type: 'button',
                title: 'Close the sheet',
                html: '&times;'
            });
            controls.append(closeButton);
            contents.prepend(controls); // Prepend to ensure it appears at the top
        }

        // Add overlay only if it's not a draggable sheet
        if (!sheet.hasClass('draggable-sheet') && !sheet.find('.overlay').length) {
            const overlay = $('<div>', { class: 'overlay' });
            sheet.prepend(overlay);
        }

        // Add footer if it's not a draggable sheet and it doesn't exist
        if (!sheet.hasClass('draggable-sheet') && !contents.find('.sheet-footer').length) {
            const footer = $('<footer>', { class: 'sheet-footer' });
            const span = $('<span>'); // Placeholder for any footer content or styles
            footer.append(span);
            contents.append(footer);
        }
    }

    // Function to set sheet visibility
    const setIsSheetShown = (sheet, isShown, button) => {
        sheet.attr("aria-hidden", String(!isShown));
        if (isShown) {
            sheet.addClass('visible');
            if (sheet.hasClass('draggable-sheet')) {
                // Set the min-height for the draggable sheet
                sheet.css('min-height', `${MIN_SHEET_HEIGHT}dvh`);
                sheet.css('height', 'auto'); // Reset height to auto to accommodate content
                sheet.find('.sheet-contents').css('height', `${DEFAULT_SHEET_HEIGHT}dvh`);
            } else {
                sheet.find('.sheet-contents').css('height', 'auto'); // Auto height for non-draggable sheets
            }
            if (button) {
                button.addClass('active'); // Add active class to button
            }
        } else {
            sheet.removeClass('visible');
            if (button) {
                button.removeClass('active'); // Remove active class from button
            }
        }
    };


    // Function to update the nearby bars dropdown when the sheet is opened
    function updateNearbyBarsOnSheetOpen(sheet) {
        if (sheet.attr('id') === 'sheet-add') {
            updateNearbyBarsDropdown(); // Call the function to update the dropdown
        }
    }

    // Toggle sheet visibility
    // Modify the toggleSheetVisibility function to update dropdown on sheet open
    const toggleSheetVisibility = (sheet, button) => {
        const isCurrentlyShown = sheet.attr("aria-hidden") === "false";
        closeAllSheets(); // Close all other sheets first
        setIsSheetShown(sheet, !isCurrentlyShown, button);

        if (!isCurrentlyShown) { // Sheet is being opened
            updateNearbyBarsOnSheetOpen(sheet); // Update the dropdown if opening sheet-add
        }
    };

    // Close all open sheets
    function closeAllSheets() {
        $(".sheet").each(function () {
            setIsSheetShown($(this), false);
        });
        // Remove active class from all buttons
        $(".open-sheet").removeClass('active');
    }

    // Initialize draggable sheet
    function initDraggableSheet(sheet) {
        setupSheet(sheet); // Set up with draggable features

        const sheetContents = sheet.find(".sheet-contents");
        const draggableArea = sheetContents.find('.draggable-area');

        // Calculate initial sheet height as a percentage of viewport height
        let sheetHeight = DEFAULT_SHEET_HEIGHT;

        // Function to set sheet height and toggle fullscreen class
        function setSheetHeight(value) {
            sheetHeight = Math.max(MIN_SHEET_HEIGHT, Math.min(100, value)); // Limit height between MIN_SHEET_HEIGHT and 100%
            sheetContents.css('height', `${sheetHeight}dvh`);
            sheet.css('min-height', `${MIN_SHEET_HEIGHT}dvh`); // Set min-height instead of height

            if (sheetHeight === 100) {
                sheetContents.addClass("fullscreen");
            } else {
                sheetContents.removeClass("fullscreen");
            }
        }

        let dragPosition;
        let isDragging = false;

        // Utility to get touch or mouse position
        const touchPosition = (event) => event.originalEvent.touches ? event.originalEvent.touches[0] : event;

        // Start dragging
        draggableArea.on("mousedown touchstart", function (event) {
            dragPosition = touchPosition(event).pageY; // Record initial touch/mouse position
            sheetContents.addClass("not-selectable"); // Prevent text selection while dragging
            $('body').css('cursor', 'grabbing'); // Change cursor to grabbing
            isDragging = true; // Set dragging state
        });

        // Dragging motion
        $(window).on("mousemove touchmove", function (event) {
            if (!isDragging) return; // Exit if not dragging

            const y = touchPosition(event).pageY; // Current touch/mouse position
            const deltaY = dragPosition - y; // Change in position
            const deltaHeight = deltaY / $(window).height() * 100; // Convert to percentage

            setSheetHeight(sheetHeight + deltaHeight); // Update sheet height
            dragPosition = y; // Update last position
        });

        // End dragging
        $(window).on("mouseup touchend", function (event) {
            if (!isDragging) return; // Exit if not dragging

            dragPosition = undefined; // Clear drag position
            sheetContents.removeClass("not-selectable"); // Re-enable text selection
            $('body').css('cursor', ''); // Reset cursor
            isDragging = false; // Reset dragging state

            // Dismiss sheet if dragged below a threshold
            if (sheetHeight < MIN_SHEET_HEIGHT + 5) { // Add a small tolerance to allow dismissal
                setIsSheetShown(sheet, false);
            } else if (sheetHeight > 75) {
                setSheetHeight(100); // Maximize if nearly fullscreen
            }
        });

        // Stop click propagation inside sheet contents to prevent unwanted closing
        sheetContents.on("click", function (event) {
            event.stopPropagation();
        });

        bindSheetToggle(sheet);
    }

    // Initialize non-draggable sheet
    function initNonDraggableSheet(sheet) {
        setupSheet(sheet); // Set up without draggable features
        bindSheetToggle(sheet);
    }

    // Bind toggle functionality for sheets
    function bindSheetToggle(sheet) {
        sheet.find(".close-sheet").on("click", function () {
            setIsSheetShown(sheet, false); // Close sheet on close button click
        });

        sheet.find(".overlay").on("click", function () {
            setIsSheetShown(sheet, false); // Close sheet when clicking overlay
        });

        // Close sheet on Escape key press
        $(window).on("keyup", function (event) {
            const isSheetElementFocused = sheet.has(document.activeElement).length;
            if (event.key === "Escape" && !isSheetElementFocused) {
                setIsSheetShown(sheet, false);
            }
        });
    }

    // Initialize sheets based on class attributes
    $(".sheet").each(function () {
        const sheet = $(this);

        if (sheet.hasClass('draggable-sheet')) {
            initDraggableSheet(sheet); // Initialize as draggable if class is present
        } else {
            initNonDraggableSheet(sheet); // Otherwise, initialize as non-draggable
        }
    });

    // Bind open events for buttons
    $(".open-sheet").each(function() {
        const button = $(this);
        const targetId = button.data('target'); // Get target sheet ID
        const sheet = $(`#${targetId}`); // Select sheet by ID

        button.on("click", function() {
            toggleSheetVisibility(sheet, button); // Toggle the target sheet and manage the button's active class
        });
    });

    // Expose function for global access
    window.setIsSheetShown = setIsSheetShown;
    window.closeAllSheets = closeAllSheets; // Make sure closeAllSheets is globally accessible
});

