let events = [];


// Fetch event details and display them in the bottom sheet
// function showEventDetails(event) {
//     const detailsContent = `
//         <strong>Event Name:</strong> ${event.name || 'Not available'}<br/>
//         <strong>Date:</strong> ${event.dateFrom || 'Not available'}<br/> ${event.dateTo} ${event.timeTo}
//         <strong>Location:</strong> ${event.location || 'Not available'}<br/>
//         <strong>Description:</strong> ${event.description || 'No description'}<br/>
//         ${event.detailsLink ? `<a href="${event.detailsLink}" target="_blank">More details</a>` : ''}
//     `;
//     $('#eventDetails').html(detailsContent);
//     setIsSheetShown($('#eventSheet'), true); // Show the event sheet
// }




// show global events in a list
function populateEventsList(events) {
    const eventsUl = $('#eventsUl');
    eventsUl.empty(); // Clear the list before populating

    if (!events || events.length === 0) {
        console.warn('No events to display.');
        eventsUl.append('<p>No events found</p>');
        return;
    }

    // Create a map to store the next upcoming event for each place_id
    const nextEventsByVenue = {};

    events.forEach(event => {
        const today = new Date();
        let eventDate = event.date_from ? new Date(event.date_from) : null;
    
        // If the event has a specific date, use it; otherwise, it's recurring or descriptive
        if (eventDate && eventDate >= today) {
            // If there's already an event for this venue, compare dates
            if (!nextEventsByVenue[event.place_id] || new Date(nextEventsByVenue[event.place_id].date_from) > eventDate) {
                nextEventsByVenue[event.place_id] = event;
            }
        } else if (event.is_recurring || event.raw_date_description) {
            // For recurring or descriptive events, prioritize them if no other future events are found
            if (!nextEventsByVenue[event.place_id]) {
                nextEventsByVenue[event.place_id] = event;
            }
        }
    });
    

    // Now populate the event list with the next event from each venue
    Object.values(nextEventsByVenue).forEach(event => {
        const eventItem = `
            <div class="event-item">
                ${event.event_image ? `<div class="event-image"><img src="${event.event_image}" alt="Event Image"></div>` : ''}
                <div class="event-item-content">
                    <div class="event-name">${event.event_name}</div>
                    ${event.date_from ? `<div class="event-date">${formatDate(event.date_from)}${event.date_to ? ' - ' + formatDate(event.date_to) : ''}</div>` : ''}
                    ${event.description ? `<div class="event-description">${event.description}</div>` : ''}
                    ${event.link ? `<div class="event-link"><a href="${event.link}" target="_blank">More details</a></div>` : ''}
                </div>
            </div>
        `;
        eventsUl.append(eventItem);
    });
}

// Helper function to format dates (e.g., "2024-09-13" -> "13 Sep 2024")
function formatDate(dateString) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

// Helper function to format the date to 'DD MMM YYYY'
// Helper function to format the date to 'DD MMM YYYY'
function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
}

// Helper function to fetch events for the venue and populate the event info
function fetchEventsForVenue(place_id, eventInfoElement) {
    $.ajax({
        type: 'GET',
        url: 'get_events.php',
        data: { place_ids: JSON.stringify([place_id]) },
        dataType: 'json',
        success: function(eventResponse) {
            if (eventResponse && eventResponse.events && eventResponse.events.length > 0) {
                let eventContent = '';

                eventResponse.events.forEach(event => {
                    // Use raw_date_description if date_from is null, otherwise use formatted date_from/date_to
                    const formattedDate = event.date_from 
                        ? (event.date_to 
                            ? `${formatDate(event.date_from)} - ${formatDate(event.date_to)}` 
                            : formatDate(event.date_from))
                        : event.raw_date_description;
                
                    eventContent += `
                        <div class="event-item">
                            ${event.event_image ? `<div class="event-image"><img src="${event.event_image}" alt="Event Image"></div>` : ''}
                            <div class="event-item-content">
                                <div class="event-name">${event.event_name}</div>
                                <div class="event-date">${formattedDate || 'Date not available'}</div>
                                ${event.time_from && event.time_to ? `<div class="event-time">${event.time_from} - ${event.time_to}</div>` : ''}
                                ${event.description ? `<div class="event-description">${event.description}</div>` : ''}
                                ${event.link ? `<div class="event-link"><a href="${event.link}" target="_blank">More details</a></div>` : ''}
                            </div>
                        </div>
                    `;
                });
                

                eventInfoElement.innerHTML = eventContent;
                eventInfoElement.style.display = 'flex';
            } else {
                eventInfoElement.style.display = 'none';
            }
        },
        error: function(xhr, status, error) {
            console.error('Error fetching event details:', error);
        }
    });
}



